var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticStyle: { position: "relative" } },
        [
          _c("head-layout", {
            attrs: {
              "head-title": "我的培训",
              "head-btn-options": _vm.headBtnOptions,
            },
          }),
          _c(
            "div",
            { staticClass: "btn-container" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "medium", icon: "el-icon-close" },
                  on: { click: _vm.occlude },
                },
                [_vm._v("关闭")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-container",
        [
          _c(
            "el-main",
            { staticClass: "videoTopBox" },
            [
              _vm.fileType != "video"
                ? _c("iframe", {
                    ref: "fileIframe",
                    staticStyle: {
                      width: "100%",
                      height: "calc(100vh - 46px)",
                    },
                    attrs: { src: _vm.videoUrl, frameborder: "0" },
                  })
                : _vm._e(),
              _vm.fileType == "video"
                ? _c("video", {
                    ref: "video",
                    staticStyle: { width: "70%" },
                    attrs: { disablePictureInPicture: "", src: _vm.videoUrl },
                    domProps: { muted: false },
                  })
                : _vm._e(),
              _vm.fileType == "video"
                ? _c("div", { staticClass: "timeBox" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.formatTime(_vm.currentTime)) +
                        "/" +
                        _vm._s(_vm.formatTime(_vm.totalTime)) +
                        "\n      "
                    ),
                  ])
                : _vm._e(),
              _vm.fileType == "video"
                ? _c("el-button", {
                    staticClass: "videoBtn",
                    attrs: {
                      type: "primary",
                      icon: _vm.videoType
                        ? "el-icon-video-pause"
                        : "el-icon-video-play",
                    },
                    on: { click: _vm.videoPlay },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "right-container",
              style: { paddingLeft: _vm.rightShow ? "" : "16px" },
            },
            [
              _vm.rightShow
                ? _c("el-button", {
                    staticClass: "rightBtn",
                    staticStyle: {
                      "margin-right": "5px",
                      "clip-path": "polygon(25% 28%, 66% 4%, 66% 96%, 25% 68%)",
                      background: "#d2d3d6",
                    },
                    attrs: {
                      circle: "",
                      type: "primary",
                      icon: "el-icon-arrow-right",
                    },
                    on: {
                      click: function ($event) {
                        _vm.rightShow = !_vm.rightShow
                      },
                    },
                  })
                : _c("el-button", {
                    staticClass: "rightBtn",
                    staticStyle: {
                      "margin-right": "5px",
                      "clip-path": "polygon(25% 28%, 66% 4%, 66% 96%, 25% 68%)",
                      background: "#d2d3d6",
                    },
                    attrs: {
                      circle: "",
                      type: "primary",
                      icon: "el-icon-arrow-left",
                    },
                    on: {
                      click: function ($event) {
                        _vm.rightShow = !_vm.rightShow
                      },
                    },
                  }),
              _c(
                "transition",
                { attrs: { name: "el-fade-in-linear" } },
                [
                  _c(
                    "el-tabs",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.rightShow,
                          expression: "rightShow",
                        },
                      ],
                      on: { "tab-click": _vm.handleClick },
                      model: {
                        value: _vm.activeName,
                        callback: function ($$v) {
                          _vm.activeName = $$v
                        },
                        expression: "activeName",
                      },
                    },
                    [
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "视频", name: "video" } },
                        [
                          _c(
                            "div",
                            { staticClass: "videoBox" },
                            _vm._l(_vm.kmInfoVideoList, function (item, index) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticClass: "videoLittleBox",
                                  style: {
                                    border:
                                      _vm.videoActive == item.id
                                        ? "1px solid #cccccc"
                                        : "",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.clickVideo(item)
                                    },
                                  },
                                },
                                [
                                  _c("video", {
                                    staticClass: "littleVideo",
                                    attrs: { src: item.fileCover },
                                  }),
                                  _c("div", { staticClass: "videoTitle" }, [
                                    _vm._v(_vm._s(item.fileName)),
                                  ]),
                                ]
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                      _c(
                        "el-tab-pane",
                        { attrs: { label: "课件", name: "file" } },
                        [
                          _c(
                            "div",
                            { staticClass: "videoBox" },
                            _vm._l(
                              _vm.kmInfoCoursewareList,
                              function (item, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "fileItem",
                                    style:
                                      _vm.fileActive == item.id ? "active" : "",
                                    on: {
                                      click: function ($event) {
                                        return _vm.getTrainCourseList(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.coursewareName) +
                                        "\n              "
                                    ),
                                  ]
                                )
                              }
                            ),
                            0
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }