<template>
  <div>
    <div style="position: relative">
      <head-layout head-title="我的培训" :head-btn-options="headBtnOptions">
      </head-layout>
      <div class="btn-container">
        <el-button size="medium" icon="el-icon-close" @click="occlude"
          >关闭</el-button
        >
      </div>
    </div>
    <el-container>
      <el-main class="videoTopBox">
        <iframe
          ref="fileIframe"
          :src="videoUrl"
          frameborder="0"
          v-if="fileType != 'video'"
          style="width: 100%; height: calc(100vh - 46px)"
        ></iframe>
        <video
          style="width: 70%"
          ref="video"
          :muted="false"
          disablePictureInPicture
          :src="videoUrl"
          v-if="fileType == 'video'"
        ></video>
        <div class="timeBox" v-if="fileType == 'video'">
          {{ formatTime(currentTime) }}/{{ formatTime(totalTime) }}
        </div>
        <el-button
          type="primary"
          v-if="fileType == 'video'"
          :icon="videoType ? 'el-icon-video-pause' : 'el-icon-video-play'"
          class="videoBtn"
          @click="videoPlay"
        ></el-button>
      </el-main>
      <div
        class="right-container"
        :style="{ paddingLeft: rightShow ? '' : '16px' }"
      >
        <el-button
          class="rightBtn"
          v-if="rightShow"
          style="
            margin-right: 5px;
            clip-path: polygon(25% 28%, 66% 4%, 66% 96%, 25% 68%);
            background: #d2d3d6;
          "
          circle
          type="primary"
          icon="el-icon-arrow-right"
          @click="rightShow = !rightShow"
        ></el-button>
        <el-button
          class="rightBtn"
          circle
          v-else
          type="primary"
          icon="el-icon-arrow-left"
          style="
            margin-right: 5px;
            clip-path: polygon(25% 28%, 66% 4%, 66% 96%, 25% 68%);
            background: #d2d3d6;
          "
          @click="rightShow = !rightShow"
        ></el-button>
        <transition name="el-fade-in-linear">
          <el-tabs
            v-model="activeName"
            v-show="rightShow"
            @tab-click="handleClick"
          >
            <el-tab-pane label="视频" name="video">
              <div class="videoBox">
                <div
                  class="videoLittleBox"
                  :style="{
                    border: videoActive == item.id ? '1px solid #cccccc' : '',
                  }"
                  v-for="(item, index) in kmInfoVideoList"
                  :key="index"
                  @click="clickVideo(item)"
                >
                  <video class="littleVideo" :src="item.fileCover"></video>
                  <div class="videoTitle">{{ item.fileName }}</div>
                </div>
              </div>
            </el-tab-pane>
            <el-tab-pane label="课件" name="file">
              <div class="videoBox">
                <div
                  class="fileItem"
                  v-for="(item, index) in kmInfoCoursewareList"
                  :style="fileActive == item.id ? 'active' : ''"
                  :key="index"
                  @click="getTrainCourseList(item)"
                >
                  {{ item.coursewareName }}
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>
        </transition>
      </div>
    </el-container>
  </div>
</template>

  <script>
import GridLayout from "@/views/components/layout/grid-layout";
import CommonTree from "@/views/components/com_tree";
import { mapGetters } from "vuex";
import HeadLayout from "@/views/components/layout/head-layout";
import website from "@/config/website";
import { Base64 } from "js-base64";
import {
  saveVideo,
  getDetail,
  getVideo,
} from "@/api/training/trainingEducation";
export default {
  data() {
    return {
      rightShow: true,
      activeName: "video",
      id: "",
      videoUrl: "",
      activeIndex: 0,
      totalTime: 0,
      currentTime: 0,
      attachTime: "",
      videoUrlList: [],
      interval: null,
      intervalFive: null,
      whetherToWatch: "",
      videoType: true,
      fileType: "",
      videoId: "",
      videoActive: "",
      fileActive: "",
      kmInfoVideoList: [],
      kmInfoCoursewareList: [],
    };
  },

  components: {
    GridLayout,
    CommonTree,
    HeadLayout,
  },

  computed: {
    ...mapGetters(["userInfo", "themeColor"]),
    headBtnOptions() {
      let buttonBtn = [];
      return buttonBtn;
    },
  },
  created() {
    let url = window.location.href;
    let p = url.split("?")[1];
    if (p) {
      let query = new URLSearchParams(p);
      let id = query.get("id");
      let videoId = query.get("videoId");
      let fileType = query.get("fileType");
      this.trainGroupScenDetailList(id, videoId, fileType);
    }
  },
  mounted() {},

  methods: {
    handleClick(e) {
      if (e.name == "file") {
        this.clearTime();
      }
    },
    trainGroupScenDetailList(id, videoId, fileType) {
      getDetail(id).then((res) => {
        if (res.data.code == 200) {
          //课件内容
          this.kmInfoCoursewareList = res.data.data.educationCoursewares;
          //视频内容
          this.kmInfoVideoList = res.data.data.educationVideos;
          let row = {};
          if (fileType == "video") {
            let videoList = this.kmInfoVideoList.filter(
              (item) => item.id == videoId
            );
            row = videoList[0];
          } else {
            let fileList = this.kmInfoVideoList.filter(
              (item) => item.id == videoId
            );
            row = fileList[0];
          }
          this.getTrainCourseList(row);
        }
      });
    },
    occlude() {
      window.close();
    },
    formatTime(seconds) {
      let hours = Math.floor(seconds / 3600);
      let minutes = Math.floor((seconds % 3600) / 60);
      let remainingSeconds = seconds % 60;
      if (hours <= 9) {
        hours = "0" + hours;
      }
      if (minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (remainingSeconds <= 9) {
        remainingSeconds = "0" + remainingSeconds;
      }
      return `${hours}:${minutes}:${remainingSeconds}`;
    },
    clearTime() {
      clearInterval(this.interval);
      clearInterval(this.intervalFive);
    },
    getTrainCourseList(row) {
      // 开始培训
      this.fileType = row.fileType;
      if (this.fileType == "video") {
        let id = row.id;
        let timeParts = row.duration.split(":");
        let duration =
          parseInt(timeParts[0]) * 3600 +
          parseInt(timeParts[1]) * 60 +
          parseInt(timeParts[2]);
        let progress = "2";
        let watchDuration = row.watchDuration;
        if (row.watchDuration === duration) {
          watchDuration = "0";
        }
        if (row.schedule == "100%") {
          progress = "1";
        }
        let data = {
          attachSumTime: duration,
          attachTime: watchDuration,
          attachUrl: row.fileCover,
          fileType: row.fileType,
          id: id,
          whetherToWatch: progress,
        };
        let dataList = [];
        dataList.push(data);
        this.videoUrlList = dataList;
        this.videoId = id;
        this.videoActive = row.id;
        this.totalTime = duration;
        this.videoUrl = this.videoUrlList[0].attachUrl;
        this.currentTime = Number(this.videoUrlList[0].attachTime);
        this.whetherToWatch = this.videoUrlList[0].whetherToWatch;
        this.$nextTick(() => {
          this.getVideoTime();
          this.getFiveSave();
        });
      } else {
        this.fileActive = row.id;
        let dataUrl = row.fileCover;
        this.getKKFileUrl(dataUrl);
      }
    },
    clickVideo(item) {
      getVideo(item.id).then((res) => {
        if (res.data.code == 200) {
          let dataObj = res.data.data;
          item.duration = dataObj.duration;
          item.watchDuration = dataObj.watchDuration;
          this.clearTime();
          this.getTrainCourseList(item);
        }
      });
    },
    doUpdateTrainCourse() {
      //   if (this.whetherToWatch == 2) {
      let schedule =
        (Math.floor(this.$refs.video.currentTime) / this.totalTime) * 100;
      schedule = Math.floor(schedule) + "%";
      let currentTime = Math.floor(this.$refs.video.currentTime);
      let data = {
        id: this.videoId,
        watchDuration: currentTime,
        schedule,
      };
      saveVideo(data);
      //   }
    },
    videoPlay() {
      let video = this.$refs.video;
      if (this.videoType) {
        video.pause();
      } else {
        video.play();
      }
      this.videoType = !this.videoType;
    },
    getVideoTime() {
      let video = this.$refs.video;
      if (this.currentTime != "" || this.currentTime != 0) {
        if (this.whetherToWatch == 2) {
          if (this.currentTime >= 7) {
            video.currentTime = this.currentTime - 3;
          } else {
            video.currentTime = this.currentTime;
          }
        } else {
          video.currentTime = 0;
        }
      }
      video.play();
      video.onloadedmetadata = () => {
        this.totalTime = Math.floor(video.duration);
      };
      let activeNum = 0;
      this.kmInfoVideoList.forEach((item, index) => {
        if (item.id == this.videoActive) {
          activeNum = index;
        }
      });
      this.interval = setInterval(() => {
        this.currentTime = Math.floor(video.currentTime);
        if (this.currentTime == this.totalTime) {
          this.doUpdateTrainCourse();
          this.videoUrlList[0].whetherToWatch = 1;
          activeNum++
          if (activeNum <= this.kmInfoVideoList.length) {
            this.$nextTick(() => {
              this.clearTime();
              this.getTrainCourseList(this.kmInfoVideoList[activeNum]);
            });
          } else {
            this.videoType = false
            video.pause();
            this.clearTime();
          }
        }
      }, 1000);
    },
    getKKFileUrl(dataUrl) {
      this.videoUrl =
        process.env.VUE_APP_BASE_KKFILEURL +
        "?url=" +
        encodeURIComponent(Base64.encode(dataUrl));
      //exls表格预览隐藏打印按钮
      if (process.env.NODE_ENV === "production") {
        const iframe = this.$refs.fileIframe;
        const iframeDocument =
          iframe.contentDocument || iframe.contentWindow.document;
        // 在 iframe 内部隐藏元素
        if (iframeDocument) {
          const elementToHide = iframeDocument.getElementById("button-area");
          if (elementToHide) {
            elementToHide.style.display = "none";
          }
        }
      }
    },
    getFiveSave() {
      this.intervalFive = setInterval(() => {
        this.doUpdateTrainCourse();
      }, 6000);
    },
  },
};
</script>
  <style>
.file-dialog .el-dialog__body {
  padding: 0 10px 10px 10px !important;
}
</style>
  <style scoped lang='scss'>
.btn-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: 5px;
  right: 12px;

  ::v-deep .el-button:focus,
  ::v-deep .el-button:hover {
    color: #303133;
    border-color: #f6f7fa;
    background-color: #f6f7fa;
  }
}
.videoTopBox {
  background-color: #303133;
  position: relative;
  height: calc(100vh - 46px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeBox {
  position: absolute;
  right: 3px;
  bottom: 0;
  color: #ffffff;
  font-size: 20px;
}

.videoBtn {
  position: absolute;
  bottom: 3px;
  left: 3px;
}
.videoBox {
  padding: 10px;
  height: calc(100vh - 106px);
  background: #fff;
  overflow-y: auto;
  width: 280px;
}

.videoLittleBox {
  width: calc(100% - 20px);
  height: 100px;
  padding: 10px;
  cursor: pointer;
  border: 1px solid transparent;
  margin-bottom: 10px;
}

.littleVideo {
  width: 100%;
  height: calc(100% - 27px);
}

.videoTitle {
  width: 90%;
  text-align: center;
  color: #333333;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.fileItem {
  text-decoration: underline;
  color: #333333;
  cursor: pointer;
  line-height: 30px;
  font-size: 14px;
}
.active {
  color: #409eff;
}
::v-deep .videoBtn i {
  font-size: 22px;
}
.right-container {
  padding-left: 10px;
  position: relative;
}

.rightBtn {
  width: 48px;
  height: 96px;
  position: absolute;
  left: -20px;
  top: calc(50% - 48px);
  z-index: 99;
}

::v-deep .el-tabs__header {
  margin: 0;
  padding: 0 12px;
}

::v-deep .el-tabs__item {
  color: #d2d3d6;
}

::v-deep .el-tabs__item.is-active {
  color: #303133;
}

::v-deep .el-tabs__active-bar {
  background-color: #303133;
}

::v-deep .el-tabs__item:hover {
  color: #303133;
}
</style>
